import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import Documents from 'components/Documents/Documents';

let axiosCancelToken = null;

export default function Notices() {
	
	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		
	}, []);


	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Documentos de la comunidad</h1>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-body p-0">
							<Documents />
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


