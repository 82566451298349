import React from 'react';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { useNavigate } from "react-router-dom";
import { getUser } from 'helpers/user';
import { getConfig } from 'helpers/config';
import BulletinBoard from './BulletinBoard';

const Wrapper = styled.div`
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display:         flex;
	flex-wrap: wrap;

	& > [class*='col-'] {
		display: flex;
		flex-direction: column;
	}
`;

const Button = styled.button`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: white;
	margin-bottom: 20px;
	background: var(--bs-blue-light);
	

	&:hover {
		color: white;
		background: var(--bs-primary);
	}

	i {
		font-size: 50px;
		line-height: 30px;
	}
	
	span {
		margin-top: 10px;
		font-weight: 500;
	}
`;

export default function Dashboard() {
	let navigate = useNavigate();

	return (
		<LoggedLayout>
			<section>
				<div className="page-content mt-5">
					<Wrapper className="row">
						<div className="col-md-12">
							<BulletinBoard />
						</div>

						<div className="col-lg-4 col-md-3 col-sm-6">
							<Button className="btn" onClick={() => navigate('/properties')}>
								<i className="bi bi-house"></i>
								<span>MIS PROPIEDADES</span>
							</Button>
						</div>
						<div className="col-lg-4 col-md-3 col-sm-6">
							<Button className="btn" onClick={() => navigate('/receipts')}>
								<i className="bi bi-map"></i>
								<span>RECIBOS</span>
							</Button>
						</div>
						<div className="col-lg-4 col-md-3 col-sm-6">
							<Button className="btn" onClick={() => navigate('/previsions')}>
								<i className="bi bi-bank"></i>
								<span>PREVISIONES DE PAGO</span>
							</Button>
						</div>
						<div className="col-lg-4 col-md-3 col-sm-6">
							<Button className="btn" onClick={() => navigate('/notices')}>
								<i className="bi bi-bell"></i>
								<span>INCIDENCIAS</span>
							</Button>
						</div>
						{ getConfig()?.company?.phone &&
							<div className="col-lg-4 col-md-3 col-sm-6">
								<Button className="btn" onClick={() => window.location.href = 'callto:' + getConfig().company.phone}>
									<i className="bi bi-phone"></i>
									<span>LLAMAR</span>
								</Button>
							</div>
						}

						{ getUser()?.important &&
							<React.Fragment>
								<hr />

								<h4 className="text-secondary text-center w-100 mb-3">PRESIDENTE</h4>

								<div className="col-lg-4 col-md-3 col-sm-6">
									<Button className="btn" onClick={() => navigate('/community-documents')}>
										<i className="bi bi-folder"></i> 
										<span>DOCUMENTOS COMUNIDAD</span>
									</Button>
								</div>

								<div className="col-lg-4 col-md-3 col-sm-6">
									<Button className="btn" onClick={() => navigate('/providers-invoices')}>
										<i className="bi bi-receipt"></i> 
										<span>FACTURAS</span>
									</Button>
								</div>

								<div className="col-lg-4 col-md-3 col-sm-6">
									<Button className="btn" onClick={() => navigate('/treasury')}>
										<i className="bi bi-currency-euro"></i> 
										<span>TESORERÍA</span>
									</Button>
								</div>
								<div className="col-lg-4 col-md-3 col-sm-6">
									<Button className="btn" onClick={() => navigate('/contracts')}>
										<i className="bi bi-file-earmark-person"></i> 
										<span>CONTRATOS</span>
									</Button>
								</div>
								<div className="col-lg-4 col-md-3 col-sm-6">
									<Button className="btn" onClick={() => navigate('/estimates')}>
										<i className="bi bi-cash"></i> 
										<span>PRESUPUESTOS</span>
									</Button>
								</div>
								<div className="col-lg-4 col-md-3 col-sm-6">
									<Button className="btn" onClick={() => navigate('/all-properties')}>
										<i className="bi bi-house"></i>
										<span>PROPIEDADES</span>
									</Button>
								</div>
							</React.Fragment>
						}

						{/* 
						<div className="col-lg-4 col-md-3 col-sm-6">
							<Button className="btn" onClick={() => navigate('/estimates')}>
								<i className="bi bi-cash"></i>
								<span>PRESUPUESTOS</span>
							</Button>
						</div>
						<div className="col-lg-4 col-md-3 col-sm-6">
							btn comunidad si es presidente
						</div>
						<div className="col-lg-4 col-md-3 col-sm-6">
							btn facturas si es presidente
						</div>
						<div className="col-lg-4 col-md-3 col-sm-6">
							btn tesorería si es presidente
						</div>
						<div className="col-lg-4 col-md-3 col-sm-6">
							btn contratos si es presidente
						</div>
						<div className="col-lg-4 col-md-3 col-sm-6">
							btn propiedades si es presidente
						</div> */}
					</Wrapper>
				</div>
			</section>
		</LoggedLayout>
	);
}


