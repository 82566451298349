import React, { useRef } from "react";
import { Navigate, NavLink } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import { getUser, setUser } from 'helpers/user';
import { setConfig, debugMode } from 'helpers/config';
import { setCommunity } from 'helpers/community';
import useStore from "store";

export default function Login() {
    const dispatch = useStore(state => state.dispatch);

	const usernameRef = useRef();
	const passwordRef = useRef();
 
	const login = (e) => {
		e.preventDefault();

		axios.get('sanctum/csrf-cookie').then(response => {
			axios.post('api/login-app', {
			    username: usernameRef.current.value,
			    password: passwordRef.current.value
		  	})
		  	.then((response) => {
				// Fix
				dispatch({
					type: 'setSidebarVisible',
					status: window.innerWidth > 400 ? true : false
				});

		  		setConfig(response.data.config);
				setCommunity(response.data.community, false, false);
		    	setUser(response.data.user, true);
		  	})
		  	.catch((error) => {
		  		toast.error('Datos inválidos');
		  	});
		});
	};

	if ( getUser() ) return <Navigate to="/" />;

	return (
		<div className="container">
			<div className="row justify-content-center mt-5">
				<div className="col-md-3">
					<h4 className="card-title text-center mb-3">Portal propietarios</h4>

					<form onSubmit={(e) => login(e)}>
						<div className="mb-3">
							<input type="text" ref={usernameRef} defaultValue={debugMode() ? '6-1234' : ''} className="form-control form-control-sm bg-white" placeholder="Usuario" />
						</div>
						<div className="mb-3">
							<input type="password" ref={passwordRef} defaultValue={debugMode() ? '123456' : ''} className="form-control form-control-sm bg-white" placeholder="Contraseña" />
						</div>
						<div className="text-center">
							<button className="btn btn-primary btn-sm text-white" onClick={(e) => login(e)}>Entrar</button>	
						</div>
						{/* <div className="text-center mt-2">
							<NavLink to="/reset-password" className="btn btn-link btn-sm text-dark">¿Has olvidado tu contraseña?</NavLink>	
						</div> */}
					</form>
				</div>
			</div>
		</div>
	);
}