import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { openPopupWindow } from 'helpers/generic';
import { CristalLoader, downloadFile, downloadFileCapacitor } from 'helpers/generic';
import { Device } from '@capacitor/device';

const ExplorerStyled = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
`;

const BreadCrumbs = styled.div`
	margin-top: 5px;
	padding: 5px 10px;
	font-size: 12px;
	border-bottom: 2px solid var(--bs-gray-200);

	.clickable {
		span.name {
			color: var(--bs-primary);
			cursor: pointer;
		}
	}
`;

const ActionsBar = styled.div`
	padding: 10px;

	#add-dropdown {

		button.btn-primary {
			
			i {
				display: inline-block;
				transform: scale(1.4);
			}
		}
	}
`;

const TableScrollable = styled.div`
	overflow: auto;
	height: 100%;
`;

const Table = styled.table`
	width: 100%;

	thead {
		border-bottom: 1px solid var(--bs-gray-200);

		th {
			padding: 5px;
			font-weight: 500;

			&:first-of-type,
			&:last-of-type {
				padding-left: 10px;
			}

			&:nth-child(2) {
				width: 120px;
			}

			&:nth-child(3) {
				width: 140px;
			}

			&:nth-child(4) {
				width: 150px;
			}
		}
	}

	tbody {
		border-bottom-width: 0;

		tr {
			user-select: none;

			&:hover {
				background: var(--bs-gray-100);
			}

			td {
				padding: 5px;
				border-bottom: 1px solid var(--bs-gray-200);

				&:first-of-type,
				&:last-of-type {
					padding-left: 10px;
					white-space: nowrap;
				}

				&:last-of-type {
					width: 20px;

					.btn-light {
						background: var(--bs-gray-500);
					}
				}

				&:nth-child(2),
				&:nth-child(3) {
					font-size: 13px;
					line-height: 13px;
					white-space: nowrap;
				}

				&.folder_name {
					color: var(--bs-primary);

					i {
						&.bi-folder-fill {
							color: var(--bs-orange);
						}

						&.bi-arrow-90deg-up {
							color: var(--bs-green);
						}
					}

					span {
						display: inline-block;
						margin-left: 5px;
					}

					&:hover {
						cursor: pointer;

						span {
							text-decoration: underline;
						}
					}
				}

				&.file_name {
					color: var(--bs-primary);

					i {
						&.bi-file-fill {
							color: var(--bs-primary);
						}
					}

					a {
						display: inline-block;
						margin-left: 5px;
						text-decoration: none;

					}
				}

				.responsive_details {
					font-size: 13px;
					line-height: 13px;
					margin-left: 18px;
					margin-top: 2px;
					color: var(--bs-secondary);
				}
			}
		}
	}


	// Responsive & popup
	@media (max-width: 768px) {
		th,td {
			&:nth-child(2),
			&:nth-child(3),
			&:nth-child(4) {
				display: none;
			}
		}
	}

	@media (max-width: 450px) {
		&:nth-child(1) {
			div.d-flex {
				max-width: 250px;
				overflow: hidden;	

				a {
					display: block;
					width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
	}
`;

let axiosCancelToken = null;

export default function Explorer(props) {
	const [loading, setLoading] = useState(false);
	const [search, setSearch] = useState('');

	const folder = props.folder;
	const openFolder = props.openFolder;
	const community = props.community;
	const rootFolderId = props.rootFolderId;
 
	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();

		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	// Reset search if folder changes
	useEffect(() => {
		setSearch('');
	}, [folder]);

	const downloadFileFix = async (file) => {
		const info = await Device.getInfo();

		const file_url = '/api/app/documents/download-file/' + file.id;

		setLoading(true);
		if ( info.platform === 'web' ) {
			await downloadFile(file_url);
		} else {
			await downloadFileCapacitor(file_url);
		}
		setLoading(false);
	}

	const openFile = (e, file) => {
		e.preventDefault();
		return downloadFileFix(file);
	} 

	let resultsCount = 0;

	return (
		<React.Fragment>
			{ loading && <CristalLoader /> }
			<ExplorerStyled>
				<ActionsBar>
					<div className="row">
						<div className={'d-flex col-6'}>
							
						</div>
						<div className="col-6 col-md-2 text-end">
							
						</div>
						<div className={'col-md-4'}>
							<input type="search" className="form-control form-control-sm mt-2 mt-md-0" placeholder="Buscar" onChange={(e) => setSearch(e.target.value)} value={search} />
						</div>
					</div>
				</ActionsBar>

				<BreadCrumbs>
					<span className={folder.id !== -1 ? 'clickable' : ''} onClick={() => openFolder(null)}>
						<span className="name">Documentos</span> 
						{ folder.breadcrumbs?.length > 0 && 
							<span className="caret">&nbsp;{'>'}&nbsp;</span> 
						}
					</span>

					{ (folder.id && folder.breadcrumbs) &&
						folder.breadcrumbs.map((el, idx) => {
							let clickable = idx < folder.breadcrumbs.length-1;
							return (
								<span key={idx} className={clickable ? 'clickable' : ''} onClick={() => clickable ? openFolder(el) : null}>
									<span className="name">{el.name}</span> {clickable ? <span className="caret">{'>'}&nbsp;</span> : ''} 
								</span>
							)
						})
					}
				</BreadCrumbs>

				{ folder.id && 
					<TableScrollable>
						<Table>
							<tbody>
								{ (folder.id !== -1 && folder.id !== rootFolderId) &&
									<tr 
										key="parent"
										data-id={-1}
									>
										<td colSpan="100%" className="folder_name" onClick={() => openFolder(folder.parent ?? null)}>
											<i className="bi bi-arrow-90deg-up"></i> 
											<span>..</span>
										</td>
									</tr>
								}
								{ folder.subfolders.length > 0 &&
									folder.subfolders.map((el, idx) => {
										// Search
										if ( search.length ) {
											if ( el.name.toLowerCase().trim().indexOf(search.toLowerCase().trim()) === -1 ) {
												return null;
											} 
										}

										resultsCount++;

										// FIX: If communities folder and community selected
										if ( el.code === 'communities' && community?.id ) {
											el.name = 'Comunidad';
											el.id = community?.folder_id;
										}

										return (
											<tr 
												key={idx} 
												data-id={el.id}
											>
												<td className="folder_name" onClick={() => openFolder(el)}>
													<div className="d-flex align-items-center">
														<i className="bi bi-folder-fill"></i> 
														<span>{el.name}</span>
													</div>
												</td>
											</tr>
										);
									})
								}
								{ folder.files.length > 0 &&
									folder.files.map((el, idx) => {
										// Search
										if ( search.length ) {
											if ( el.name.toLowerCase().trim().indexOf(search.toLowerCase().trim()) === -1 ) {
												return null;
											} 
										}

										resultsCount++;

										// Get icon 
										let icon_class = 'bi ';
										if ( el.extension === 'pdf' ) icon_class += 'bi-file-pdf';
										if ( el.mimetype?.indexOf('video/') !== -1 ) icon_class += 'bi-file-earmark-play';
										if ( el.mimetype?.indexOf('image/') !== -1 ) icon_class += 'bi-file-earmark-image';
										if ( el.extension.indexOf('xls') !== -1 || el.extension === 'ods' ) icon_class += 'bi-file-earmark-excel';
										if ( el.extension.indexOf('doc') !== -1 || el.extension === 'odt' ) icon_class += 'bi-file-earmark-word';
										if ( icon_class === 'bi ') icon_class += 'bi-file-fill';

										return (
											<tr key={idx}>
												<td 
													className="file_name" 
												>
													<div className="d-flex align-items-center">
														<i className={icon_class}></i> 
														<NavLink to={'/documents/view-file/'} onClick={(e) => openFile(e, el)}>{el.name}</NavLink>
													</div>
												</td>
											</tr>
										);
									})
								}

								{ (resultsCount === 0) &&
									<tr key='empty'>
										<td colSpan="100%">No hay documentos</td>
									</tr>
								}
							</tbody>
						</Table>
					</TableScrollable>
				}
			</ExplorerStyled>
		</React.Fragment>
	);
}