import React, {useState, useEffect} from 'react';
import LoggedLayout from 'layouts/LoggedLayout';
import { default as OwnersService } from 'services/Owners';
import { useNavigate } from 'react-router-dom';
import { getUser, setUser } from 'helpers/user';

export default function SetPassword() {
	let navigate = useNavigate();

	let [password, setPassword] = useState(null);
	let [passwordAgain, setPasswordAgain] = useState(null);
	let [error, setError] = useState(false);

	useEffect(() => {
		if ( password !== null && passwordAgain !== null && password !== passwordAgain ) {
			setError('Las contraseñas no coinciden');
		} else {
			setError(false);
		}
	}, [password, passwordAgain]);

	const save = async () => {
		let result = await OwnersService.setPassword(password);
		if ( result.status !== 1 ) {
			setError(result.errors['password'][0] ?? 'Ha ocurrido un error, inténtalo de nuevo');
			return;
		} else {
			let user = getUser();
			user.has_password = true;
			setUser(user);
		}

		navigate('/');
	}
	
	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Selecciona una contraseña para la App</h1>
				</div>

				<div className="page-content">
					<div className="row">
						<div className="col-lg-6">
							<div className="card border-0 shadow-sm">
								<div className="card-body">
									<div className="row">
										<div className="col-md-6">
											<label>Introduce una contraseña nueva</label>
											<input type="text" className="form-control form-control-sm" onChange={(e) => setPassword(e.target.value)} />
										</div>
										<div className="col-md-6">
											<label>Escríbela de nuevo</label>
											<input type="text" className="form-control form-control-sm" onChange={(e) => setPasswordAgain(e.target.value)} />
											{error !== false &&
												<div className="invalid-feedback d-block">{error}</div>
											}
										</div>
									</div>
								</div>
								<div className="card-footer text-end">
									<button className="btn btn-sm btn-primary text-white" disabled={!password || !passwordAgain || error} onClick={save}>Aceptar</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


