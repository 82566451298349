import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { NavLink } from "react-router-dom";
import TrSkeleton from 'components/TrSkeleton';
import { default as ContractsService } from 'services/Contracts';
import Paginator from 'components/Paginator';
import moment from 'moment';

const Table = styled.table`
	th,
	td {
		&:nth-child(1) {
			width: 40px;
			white-space: nowrap;
		}

		&:nth-child(2) {
			width: 180px;
		}

		&:nth-child(4),
		&:nth-child(5),
		&:nth-child(6) {
			width: 120px;
		}
	}	
`;

export default function Contracts() {
	const [queryParams] = useSearchParams();

	let [contracts, setContracts] = useState(undefined);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setContracts({...contracts, data: undefined});
		_setPage(page);
	}

	useEffect(() => {
		const getContracts = async () => {
			let contracts = await ContractsService.list({
				page: page
			});
			setContracts({...contracts});
			setSkeletonRows(contracts?.data?.length);
		}

		getContracts();
	}, [page]);


	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Contratos</h1>

					<div className="ms-auto">
						<NavLink to="/" className="btn btn-sm btn-light">Volver</NavLink>
					</div>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<th>Nº</th>
											<th>Proveedor</th>
											<th>Descripción</th>
											<th>Comienza el</th>
											<th>Finaliza el</th>
											<th>Estado</th>
										</tr>
									</thead>
									<tbody>
										{ contracts?.data?.length > 0 &&
											contracts.data.map((el, idx) => {
												return ( 
													<React.Fragment key={'c'+idx}>
														<tr>
															<td>{el.number ?? '-'}</td>
															<td>{el.provider?.business_name ?? '-'}</td>
															<td>{el.description}</td>
															<td>{ moment(el.date).format('DD-MM-YYYY') }</td>
															<td>{ el.due_date ? moment(el.due_date).format('DD-MM-YYYY') : '' }</td>
															<td>
																<div className={'text-white text-center bg-' + (el.status ? 'success' : 'danger')}>
																	{ el.status ? 'Vigente' : 'No vigente' }
																</div>	
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ contracts && !contracts.data?.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ contracts === undefined && <TrSkeleton rows={skeletonRows} columns={6} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={contracts?.current_page}
									max={contracts?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


