import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { getUser, setUser } from 'helpers/user';
import { getConfig, getAjaxUrl } from 'helpers/config';
import useStore from "store";

import Login from "pages/Login";
import Logout from "pages/Logout";
import ResetPassword from "pages/ResetPassword";
import Dashboard from "pages/Dashboard";
import SetPassword from "pages/SetPassword";
import Properties from "pages/Properties";
import Receipts from "pages/Receipts";
import Previsions from "pages/Previsions";
import Notices from "pages/Notices";
import CommunityDocuments from "pages/CommunityDocuments";
import ProvidersInvoices from "pages/ProvidersInvoices";
import Treasury from "pages/Treasury";
import Contracts from "pages/Contracts";
import AllProperties from "pages/AllProperties";
import Estimates from "pages/Estimates";


const MainContainer = styled.div`
	
`;

function App() {
	const location = useLocation();

    const forceReload = useStore(state => state.forceReload);

	// Axios global
    axios.defaults.baseURL = getAjaxUrl();
	axios.defaults.headers.common = {
		'Authorization': getUser() ? 'Bearer ' + getConfig().token : null,
		'Content-Type': 'application/json',
		'Accept': 'application/json',
		'Cache-Control': 'no-cache',
		'Pragma': 'no-cache',
		'Expires': '0',
	};
    
    // Axios interceptors
    axios.interceptors.response.use((response) => {
        return response;
    }, (error) => {
        // Check unauthorized error or csrf token mismatch
        if ( error.response && (error.response.status === 401 || error.response.status === 419 || error.response.status === 403) ) {
            setUser(null, true);
        }

        // Default
        return Promise.reject(error);
    });

	return (
		<MainContainer id="main-container" forceReload={forceReload}>
			{ (!getUser() && (location.pathname !== '/login' && location.pathname !== '/reset-password' )) &&
				<Navigate to="/login" />
			}

			<Routes>
				<Route path="login" element={<Login />} />
				<Route path="logout" element={<Logout />} />
				<Route path="reset-password" element={<ResetPassword />} />
				
				<Route path="/" element={<Dashboard />} />
				<Route path="set-password" element={<SetPassword />} />
				<Route path="properties" element={<Properties />} />
				<Route path="receipts" element={<Receipts />} />
				<Route path="previsions" element={<Previsions />} />
				<Route path="notices" element={<Notices />} />

				<Route path="community-documents" element={<CommunityDocuments />} />
				<Route path="providers-invoices" element={<ProvidersInvoices />} />
				<Route path="treasury" element={<Treasury />} />
				<Route path="contracts" element={<Contracts />} />
				<Route path="all-properties" element={<AllProperties />} />
				<Route path="estimates" element={<Estimates />} />
			</Routes>
			<ToastContainer
				position="top-right"
				autoClose={2000}
			/>
		</MainContainer>
	);
}

export default App;