import axios from 'axios';

export default class Previsions {

	static axiosCancelToken = null;

	static list = (propertyId, estimateId) => {
		this.newAxiosCancelToken();

		return axios.get('api/app/previsions/list', {
			params: {
				property_id: propertyId,
				estimate_id: estimateId
			},
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data;
		})
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}