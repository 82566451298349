import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { NavLink } from "react-router-dom";
import TrSkeleton from 'components/TrSkeleton';
import { default as PropertiesService } from 'services/Properties';

const Table = styled.table`
	thead {
		th {
			white-space: nowrap;
		}
	}

	thead, tbody {
		th, td {
			&:nth-child(1) {
				width: 50px;
			}

			&:nth-child(3),
			&:nth-child(4) { 
				text-align: right;
				white-space: nowrap;
				
				@media (min-width: 1100px) {
					width: 100px;
				}
			}
		}
	}
`;

let axiosCancelToken = null;

export default function Properties() {
	let [properties, setProperties] = useState(undefined);
	let [skeletonRows, setSkeletonRows] = useState(5);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getProperties = async () => {
			let properties = await PropertiesService.list();
			setProperties([...properties]);
			setSkeletonRows(properties.length);
		}

		getProperties();
	}, []);


	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Mis propiedades</h1>

					<div className="ms-auto">
						<NavLink to="/" className="btn btn-sm btn-light">Volver</NavLink>
					</div>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<th>Nº Dep</th>
											<th>Dirección</th>
											<th>Coeficiente</th>
											<th>Saldo</th>
										</tr>
									</thead>
									<tbody>
										{ properties?.length > 0 &&
											properties.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr>
															<td>{el.number}</td>
															<td>{el.name}</td>
															<td>{parseFloat(el.coefficient).toFixed(2)} %</td>
															<td>{parseFloat(el.receipts_balance).toFixed(2)} €</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ properties && !properties.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ properties === undefined && <TrSkeleton rows={skeletonRows} columns={4} /> }
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


