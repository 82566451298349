import React, { useRef, useState, useEffect } from "react";
import { Navigate, NavLink, useNavigate, useSearchParams } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import { getUser } from 'helpers/user';
import { assets } from 'helpers/generic';
import useStore from "store";

export default function ResetPassword() {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();

	const debug = useStore(state => state.debug);

	// Request new password form
	const requestPasswordEmailRef = useRef();
	
	// Send new password form
	const newPasswordEmailRef = useRef(null);
	const newPasswordPasswordRef = useRef(null);
	const [newPasswordToken, setNewPasswordToken] = useState(searchParams.get('token'));
	const [newPasswordEmail] = useState(searchParams.get('email'));

	const requestNewPassword = (e) => {
		e.preventDefault();

		axios.get('sanctum/csrf-cookie').then(response => {

			axios.post('api/request-new-password', {
				email: requestPasswordEmailRef.current.value
			}).then(response => {
				if ( response.data.status ) {
					toast.success('Se ha enviado un email con instrucciones');
					requestPasswordEmailRef.current.value = "";
				} else {
					toast.error(response.data.error);
				}
			}).catch(error => {
				toast.error('Introduce un email válido');
			});

		});
	};

	const sendNewPassword = (e) => {
		e.preventDefault();

		axios.get('sanctum/csrf-cookie').then(response => {

			axios.post('api/reset-password', {
				token: newPasswordToken,
				email: newPasswordEmailRef.current.value,
				password: newPasswordPasswordRef.current.value,
			}).then(response => {
				if ( response.data.status ) {
					toast.success('Se ha cambiado la contraseña');
					setNewPasswordToken(false);
					navigate('/');
				} else {
					toast.error(response.data.error);
				}
			}).catch(error => {
				toast.error('Ha ocurrido un error');
			});

		});
	}

	if ( getUser() ) return <Navigate to="/" />;

	return (
		<div className="container">
			<div className="row justify-content-center mt-5">
				<div className="col-md-4">
					<h4 className="card-title text-center mb-3">
						Recuperar contraseña
					</h4>

					{ !newPasswordToken &&
						<form onSubmit={(e) => requestNewPassword(e)}>
							<div className="mb-3">
								<input type="email" ref={requestPasswordEmailRef} className="form-control form-control-sm bg-white" placeholder="Email" />
							</div>
							<div className="d-flex justify-content-between">
								<NavLink to="/login" className="btn btn-sm btn-link p-0 text-secondary">Volver</NavLink>	
								<button className="btn btn-primary btn-sm text-white" onClick={(e) => requestNewPassword(e)}>Recuperar</button>	
							</div>
						</form>
					}
					{ newPasswordToken &&
						<form onSubmit={(e) => sendNewPassword(e)}>
							<div className="mb-3">
								<input type="email" required ref={newPasswordEmailRef} defaultValue={newPasswordEmail} className="form-control form-control-sm bg-white" placeholder="Email" />
							</div>
							<div className="mb-3">
								<input type="password" required minLength="6" ref={newPasswordPasswordRef} className="form-control form-control-sm bg-white" placeholder="Nueva contraseña" />
							</div>
							<div className="d-flex justify-content-end">
								<button className="btn btn-primary btn-sm text-white" type="submit">Guardar la nueva contraseña</button>	
							</div>
						</form>
					}
				</div>
			</div>
		</div>
	);
}