import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { NavLink } from "react-router-dom";
import TrSkeleton from 'components/TrSkeleton';
import { default as EstimatesService } from 'services/Estimates';
import Paginator from 'components/Paginator';
import moment from 'moment';
import { downloadFile, downloadFileCapacitor } from 'helpers/generic';
import { getAjaxUrl } from 'helpers/config';
import { Device } from '@capacitor/device';

const Table = styled.table`
	th,td {
		&:nth-child(1) {
			width: 40px;
			white-space: nowrap;
		}

		&:nth-child(2) {
			width: 100px;
		}

		&:nth-child(4) {
			width: 150px;
		}

		&:nth-child(5) {
			width: 160px;
		}

		&:nth-child(6) {
			width: 100px;
			white-space: nowrap;
		}

		&:nth-child(7) {
			width: 100px;
			white-space: nowrap;
		}

		&:nth-child(8) {
			width: 20px;
		}
	}
`;

export default function Estimates() {
	const [queryParams] = useSearchParams();

	let [estimates, setEstimates] = useState({});
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setEstimates({...estimates, data: {}});
		_setPage(page);
	}

	useEffect(() => {
		const getEstimates = async () => {
			let estimates = await EstimatesService.list({
				page: page
			});
			setEstimates({...estimates});
			setSkeletonRows(estimates?.data?.length);
		}

		getEstimates();
	}, [page]);

	const downloadPdf = async (estimate) => {
		let file_url = getAjaxUrl() + 'api/app/estimates/get-pdf/' + estimate.id;
		let file_name = 'Presupuesto ' + estimate.number_full + '.pdf';
		file_name = file_name.replace('/', '-');

		const info = await Device.getInfo();

		if ( info.platform === 'web' ) {
			await downloadFile(file_url, file_name);
		} else {
			await downloadFileCapacitor(file_url, file_name);
		}
	}


	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Presupuestos</h1>

					<div className="ms-auto">
						<NavLink to="/" className="btn btn-sm btn-light">Volver</NavLink>
					</div>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<th>Nº</th>
											<th>Tipo</th>
											<th>Descripción</th>
											<th>Estado</th>
											<th>Fecha aprobación</th>
											<th>Últ. emisión</th>
											<th>Prox. emisión</th>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody>
										{ estimates.data?.length > 0 &&
											estimates.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr>
															<td>{el.number_full}</td>
															<td>{el.type === 'ordinary' ? 'Ordinario' : 'Extraordinario'}</td>
															<td>{el.description ?? '-'}</td>
															<td>
																<div className={'text-white text-center bg-' + (el.status ? 'success' : 'danger')}>
																	{ !el.status ?
																		el.type === 'ordinary' ? 'No vigente' : 'No aprobado'
																		:
																		el.type === 'ordinary' ? 'Vigente' : 'Aprobado'
																	}
																</div>	
															</td>
															<td>{el.approval_date ? moment(el.approval_date).format('DD-MM-YYYY') : '-'}</td>
															<td>
																{ el.lastissuance && 
																	<React.Fragment>
																		<span className="badge bg-light text-secondary">{el.lastissuance.number}</span> &nbsp;
																		{ moment(el.lastissuance.date).format('DD-MM-YYYY') }
																	</React.Fragment>
																}
																{ !el.lastissuance && '-' } 
															</td>
															<td>
																{ el.lastissuance && 
																	<React.Fragment>
																		<span className="badge bg-light text-secondary">{el.lastissuance.number + 1}</span> &nbsp;
																		{ moment(el.lastissuance.date).add(el.receipts_first_issuance_perioricity, 'months').format('DD-MM-YYYY') }
																	</React.Fragment>
																}
																{ !el.lastissuance && 
																	(
																		el.receipts_first_issuance_date ? 
																			'[1] ' + moment(el.receipts_first_issuance_date).format('DD-MM-YYYY') 
																		: 
																			'-'
																	)
																} 
															</td>
															<td>
																<button className="btn btn-sm btn-link" onClick={() => downloadPdf(el)}><i className="bi bi-save"></i></button>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ estimates.data && !estimates.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ estimates.data === undefined && <TrSkeleton rows={skeletonRows} columns={8} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={estimates?.current_page}
									max={estimates?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


