import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { NavLink, Navigate, useLocation } from "react-router-dom";
import { getUser } from 'helpers/user';
import { getCommunity } from 'helpers/community';
import { isTouchEnabled } from 'helpers/generic';
import useStore from "store";
import Sidebar from './Sidebar';

const Layout = styled.div`
	height: 100%;
`;

const Navbar = styled.nav`
	background: white;
	box-shadow: 0px 2px 10px rgb(1 41 112 / 10%);
	z-index: 21;

	.navbar-brand {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 600;
		font-family: 'Open Sans', sans-serif;
		color: var(--bs-body-color);
		margin-right: 0;
		min-width: 250px;

		img#logo {
			max-height: 30px;
			max-width: 150px;
		}

		.badge {
			font-weight: lighter;
			font-size: 10px;
		}

		@media (max-width: 992px) {
			justify-content: start;
			padding-left: 20px;
		}
	}

	.btn-navbar-toggler {
		display: none;

		&.desktop {
			margin-left: 20px;
			padding: 0;

			@media (min-width: 993px) {
				display: inline;
			}
		}

		&.mobile {
			@media (max-width: 992px) {
				display: inline;
			}
		}		
	}

	.navbar-nav {
		@media (max-width: 992px) {
			margin: 0 auto;
			margin-top: 5px !important;
			margin-bottom: 0 !important;

			&:first-of-type {
				.dropdown-menu {
					left: 0;
					right: auto;
				}
			}

			&:last-of-type {
				.dropdown-menu {
					right: 0;
    				left: auto;
				}
			}

			.dropdown-menu {
				position: absolute;
			}

			.nav-link {
				padding: 0;
			}
		}
	}

	.community_name {
		display: flex;
		font-weight: 500;

		@media screen and (max-width: 992px) {
			& {
				width: 100%;
				justify-content: center;
			}
		}
	}
`;

const DropdownTitle = styled.div`
	display: inline-flex;
	align-items: center;

	.bi {
		color: var(--bs-primary);
		font-size: 18px;
		line-height: var(--bs-body-font-size);
		margin-right: 5px;
	}
`;

const Main = styled.main`
	&:not(.is-mobile) {
		margin-left: 80px;

		&.sidebar-visible {
			margin-left: 250px;
		}
	}
	
	transition: all 0.3s;
	position: relative;	

	.page-title {
		display: flex;
		background: var(--bs-blue);
		margin-top: 55px;
		padding: 20px;
		border-bottom: 1px solid var(--bs-gray-300);
		align-items: center;

		h1 {
			display: flex;
			align-items: center;
			max-width: 1500px;

			color: white;
			font-size: 25px;
			line-height: 25px;
			margin: 0;
		}
	}

	.page-content {
		padding: 20px;
	}

	@media (max-width: 992px) {
    	& {
    		margin-left: 0 !important;
    	}
    }
`;

let axiosCancelToken = null;
let lastSidebarTop = 0;

export default function LoggedLayout(props) {
	const location = useLocation();

	const navbarRef = useRef(null);
	const [sidebarTop, setSidebarTop] = useState(lastSidebarTop);

    const dispatch = useStore(state => state.dispatch);
	const sidebarVisible = useStore(state => state.sidebarVisible);    
    const debug = useStore(state => state.debug);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	// Reposition sidebar
	useEffect(() => {
		let newSidebarTop = navbarRef.current?.getBoundingClientRect().height;
		if ( lastSidebarTop === newSidebarTop ) return;
		lastSidebarTop = newSidebarTop;
		setSidebarTop(newSidebarTop);
	}, [sidebarVisible]);

    const setSidebarVisible = (status) => {
	    dispatch({
			type: 'setSidebarVisible',
			status: status
		});
    }
    
	return (
		<Layout id="layout">
			<Navbar ref={navbarRef} className="navbar navbar-expand-lg navbar-light fixed-top">
				<div className="container-fluid px-lg-0">
					<div className="navbar-brand">
						{ getCommunity()?.logo_base64_url &&
							<img id="logo" src={getCommunity()?.logo_base64_url} alt="company-logo" />
						}
						{ debug && 
							<span className="badge bg-primary text-white ms-2">debug</span>
						}
						<button className="btn btn-navbar-toggler desktop" tabIndex="-1" onClick={() => setSidebarVisible(!sidebarVisible)}>
							<span className="navbar-toggler-icon"></span>
						</button>
					</div>
					<button className="btn btn-navbar-toggler mobile" tabIndex="-1" onClick={() => setSidebarVisible(!sidebarVisible)}>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className={'collapse navbar-collapse ' + (sidebarVisible ? 'show' : '')}>
						<div className="community_name">
							{getCommunity()?.name}
						</div>
						<div className="d-flex ms-auto">
							<ul className="navbar-nav mb-2 mb-lg-0">
								<li className="nav-item dropdown">
									<a href="/" className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown">
										<DropdownTitle>
											<i className="bi bi-person-circle bi-big"></i> { getUser()?.name }
										</DropdownTitle>
									</a>
									<ul className="dropdown-menu dropdown-menu-end">
										<li><NavLink className="dropdown-item" to="/logout">Salir</NavLink></li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</Navbar>
			
			<Sidebar top={sidebarTop} />

			<Main className={(sidebarVisible ? 'sidebar-visible' : '') + ' ' + (isTouchEnabled() ? 'is-mobile' : '')}>
				{ props.children }
			</Main>

			{ getUser() && !getUser().has_password && location.pathname !== '/set-password' && // If logged user but not password
				<Navigate to="/set-password" />
			}
		</Layout>
	);
}