import axios from 'axios';

export default class Owners {

	static axiosCancelToken = null;

	static setPassword = (password) => {
		this.newAxiosCancelToken();

		return axios.post('api/app/owners/set-password', {
			password: password
		}, {
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data;
		})
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}