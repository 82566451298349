import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { default as BulletinBoardService } from 'services/BulletinBoard';

const Table = styled.table`
	width: 100%;
	font-size: 13px;
	vertical-align: middle;

	tr {
		&:hover {
			background: var(--bs-light);
		}
	}

	td,th {
		cursor: pointer;

		&:nth-child(1) {
			width: 20px;
		}
	}
`;

export default function BulletinBoard() {
	let [announcements, setAnnouncements] = useState([]);

	useEffect(() => {
		const getAnnouncements = async () => {
			let announcements = await BulletinBoardService.list({
				no_paginate: true,
				pending: true
			});
			if ( announcements ) setAnnouncements([...announcements]);
		}
		getAnnouncements();

		return function cleanup() {
			BulletinBoardService.cancel();
		}
	}, []);

	return (
		<React.Fragment>
			{ announcements.length > 0 &&
				<div className="card border-0 shadow-sm mb-3">
					<div className="card-header bg-white p-2">
						<h5 className="card-title mb-0 text-primary d-flex h-100 align-items-center justify-content-center">Tablón de anuncios</h5>
					</div>
					<div className="card-body p-2">
						<Table className="table table-sm table-bordered mb-0">
							<tbody>
								{ announcements.map((el, idx) => {
									return (
										<tr key={idx}>
											<td>{el.description}</td>
										</tr>
									);
								})}
							</tbody>
						</Table>	
					</div>
				</div>
			}
		</React.Fragment>
	);
}


