import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';
import { FileOpener } from '@capacitor-community/file-opener';

export const assets = (asset) => {
	return process.env.PUBLIC_URL + asset;
}

export const isMobile = () => {
	if ( window.outerWidth <= 992 ) return true;
	return false;
}

export const isTouchEnabled = () => {
    return ( 'ontouchstart' in window ) ||
           ( navigator.maxTouchPoints > 0 ) ||
           ( navigator.msMaxTouchPoints > 0 );
}

export const loader = <img src={ assets('/img/loader.gif') } id="loader" alt="loader" />;

export const CristalLoader = () => {
	return (
		<div 
			className="cristal-loader"
			style={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				position: 'absolute',
				top: 0,
				left: 0,
				width: '100%',
				height: '100%',
				background: 'transparent',
				zIndex: 9999,
				userSelect: 'none'
			}}
		>
			<img 
				src={ assets('/img/loader.svg') } 
				style={{
					width: '200px',
					userSelect: 'none',
					pointerEvents: 'none'
				}}
				alt="loader"
			/>
		</div>
	);
}

export const jsonToArray = (json) => {
	let array = [];
	for(let i in json) array.push({key: i, value: json[i]});
	return array;
}

export const formatNumber = (number, decimals = 0, force_decimals = true) => {
	if ( isNaN(number) ) number = 0;
	else number = parseFloat(number);

	const split = number.toString().split('.');
	if ( split[1] ) {
		let integer = parseInt(split[1]);
		if ( integer > 0 ) {
			if ( decimals ) {
				if ( force_decimals ) number = number.toFixed(decimals);
				else {
					let decimalsStr = split[1];
					decimalsStr.replaceAll('0', '');
					number = number.toFixed(decimalsStr.length > decimals ? decimals : decimalsStr.length);
				}
			}
		}
		else number = parseInt(number);
	} else {
		number = parseInt(number);
	}
	return number;
}

export const openPopupWindow = (href = null, name = null) => {
	let screenWidth = window.screen.width;
    let screenHeight = window.screen.height;

    let windowWidth = screenWidth * 0.70;
    let windowHeight = screenHeight * 0.70;

    let left = (screenWidth - windowWidth) / 2;
    let top = (screenHeight - windowHeight) / 4;

	let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${windowWidth},height=${windowHeight},left=${left},top=${top}`;

	if ( !name ) name = 'r' + Math.random();

	return window.open(href, name, params);
}

export const readableSize = (x) => {
	const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
	let l = 0, n = parseInt(x, 10) || 0;
	while(n >= 1024 && ++l){
		n = n/1024;
	}
	return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

export const downloadFile = (url, filename = 'filename') => {
	const axios = require('axios').default;

	return axios({
	    url: url,
	    method: 'GET',
	    responseType: 'blob',
	}).then((response) => {
	    const url = window.URL.createObjectURL(new Blob([response.data]));
	    const link = document.createElement('a');
	    link.href = url;
	    link.setAttribute('download', response.headers.file_name ?? filename);
	    document.body.appendChild(link);
	    link.click();
	    link.parentElement.removeChild(link);
	    return true;
	}).catch((error) => {
		return error;
	});
}

export const downloadFileCapacitor = (url, fileName = 'filename') => {
	const axios = require('axios').default;

	return axios({
	    url: url,
	    method: 'GET',
	    responseType: 'blob',
	}).then((response) => {
		let saveToCacheAndOpen = async () => {
			const readFileAsync = (file) => {
				return new Promise((resolve, reject) => {
					let reader = new FileReader();
					reader.onload = () => {
						resolve(reader.result);
					}
					reader.onerror = reject;
					reader.readAsDataURL(file); 
				});
			}

			let outputData = await readFileAsync(response.data);
			outputData = outputData.substring(outputData.indexOf('base64,')+7);

			const createFileResult = await Filesystem.writeFile({
				path: response.headers.file_name ?? fileName,
				data: '',
				directory: Directory.Cache
			});
	
			// Save appending because large files crashes app
			let readLength = 1000000;
			let i = 0;
			while(i<outputData.length) {
				let substring = outputData.substr(i, readLength);
				i += readLength;
	
				await Filesystem.appendFile({
					path: response.headers.file_name ?? fileName,
					data: substring,
					directory: Directory.Cache
				});
			}
			
			// Get file saved path
			let fileSavedPath = createFileResult.uri;
			
			// Open file
			try {
				await FileOpener.open({
					filePath: fileSavedPath
				});
			} catch (e) {
				console.error('Error opening file.', 'Code: ' + e.code, 'Message: ' + e.message);
			}

			return true;
		}
		return saveToCacheAndOpen();
	}).catch((error) => {
		return error;
	});
}

export const ucfirst = (str) => {
    return str[0].toUpperCase() + str.slice(1);
}