import React from 'react';
import styled from 'styled-components';

const Th = styled.th`
	cursor: pointer;
	white-space: nowrap;
	user-select: none;
	color: ${props => props.active ? 'var(--bs-primary)' : ''};

	& > div.th-sortable-inner {
		display: inline-flex;
		justify-content: space-between;
		align-items: center;

		& > i {
			font-style: normal;
			margin-right: auto;
			margin-left: 10px;
			color: ${props => props.active ? '' : 'var(--bs-gray-400)'};
			width: 8px;
			max-width: 8px;
			min-width: 8px;
		}
	}
`;

export default function ThSortable(props) {
	return (
		<Th 
			onClick={() => props.onClick()} 
			style={props.style ?? {}}
			active={props.active}
		>
			<div className="th-sortable-inner">
				{ props.children }
				<i active={props.active.toString()}>
					{ !props.active &&
						'⇅'
					}
					{ props.active && 
						<React.Fragment>
							{ props.direction === 'asc' && '↑' } 
							{ props.direction === 'desc' && '↓' } 
						</React.Fragment>
					}
				</i>
			</div>
		</Th>
	);
}