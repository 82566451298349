import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { useSearchParams } from "react-router-dom";
import LoggedLayout from 'layouts/LoggedLayout';
import { NavLink } from "react-router-dom";
import TrSkeleton from 'components/TrSkeleton';
import Paginator from 'components/Paginator';
import { default as ProvidersInvoicesService } from 'services/ProvidersInvoices';
import { formatNumber, downloadFile, downloadFileCapacitor } from 'helpers/generic';
import { Device } from '@capacitor/device';

const Table = styled.table`
	th,td {
		&:nth-child(2) {
			width: 100px;
		}
	
		&:nth-child(3) {
			width: 100px;
			white-space: nowrap;
		}

		&:nth-child(4) {
			width: 80px;
			text-align: right;
			white-space: nowrap;
		}

		&:nth-child(5) {
			text-align: right;
			white-space: nowrap;
		}

		&:nth-child(6) {
			width: 80px;
			text-align: center;
		}

		&:nth-child(7) {
			width: 80px;
			text-align: center;
		}


		@media (max-width: 768px) {
			&:nth-child(4),
			&:nth-child(5) {
				display: none;
			}
		}
	}
`;

let searchTimeout = null;

export default function ProvidersInvoices() {
	const [queryParams] = useSearchParams();

	const searchQS = queryParams.get('search') ?? undefined;

	let [invoices, setInvoices] = useState({});
	let [dateFrom, setDateFrom] = useState( moment().startOf('year').format('YYYY-MM-DD') );
	let [dateTo, setDateTo] = useState( moment().endOf('year').format('YYYY-MM-DD') );
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setInvoices({...invoices, data: undefined});
		_setPage(page);
	}
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [search, setSearch] = useState(searchQS);


	const setSearchTimeout = (value) => {
		if ( searchTimeout ) clearTimeout(searchTimeout);
		searchTimeout = setTimeout(() => {
			setSearch(value);
			setPage(1);
		}, 1000);
	}

	const setDatesRange = (e, range) => {
		e.preventDefault();

		let dateFrom = null;
		let dateTo = null;
		
		switch(range) {
			case 'thismonth':
				dateFrom = moment().startOf('month').format('YYYY-MM-DD');
				dateTo = moment().endOf('month').format('YYYY-MM-DD');
			break;

			case 'lastmonth':
				dateFrom = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
			break;

			case 'thisyear':
				dateFrom = moment().startOf('year').format('YYYY-MM-DD');
				dateTo = moment().endOf('year').format('YYYY-MM-DD');
			break;

			case 'lastyear':
				dateFrom = moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD');
				dateTo = moment().subtract(1, 'years').endOf('year').format('YYYY-MM-DD');
			break;
			default: 
		}

		setDateFrom(dateFrom);
		setDateTo(dateTo);
	}

	const viewDocument = async (file) => {
		const info = await Device.getInfo();

		let file_url = '/api/app/documents/download-file/' + file.id;

		if ( info.platform === 'web' ) {
			await downloadFile(file_url);
		} else {
			await downloadFileCapacitor(file_url);
		}
	}

	useEffect(() => {
		const getInvoices = async () => {
			let invoices = await ProvidersInvoicesService.list({
				date_from: dateFrom,
				date_to: dateTo,
				page: page,
				search: search
			});
			setInvoices({...invoices});
			setSkeletonRows(invoices?.data?.length);
		}

		getInvoices();

		return function cleanup() {
			ProvidersInvoicesService.cancel();
		}
	}, [dateFrom, dateTo, page, search]);


	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Facturas</h1>

					<div className="ms-auto">
						<NavLink to="/" className="btn btn-sm btn-light">Volver</NavLink>
					</div>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
					<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-3 mb-2 mt-2 mb-md-0 text-end">
									<input type="text" className="form-control form-control-sm" placeholder="Buscar" onChange={(e) => setSearchTimeout(e.target.value)} value={searchQS} />
								</div>
								<div className="col-md-4 mb-2 mt-2 mb-md-0 text-end">
									<div className="input-group input-group-sm">
										<span className="input-group-text">
											Fecha
										</span>
										<input type="date" className="form-control form-control-sm" placeholder="Desde" value={dateFrom ?? ''} onChange={(e) => setDateFrom(e.target.value)} />
										<input type="date" className="form-control form-control-sm" placeholder="Hasta" value={dateTo ?? ''} onChange={(e) => setDateTo(e.target.value)} />
										<span className="input-group-btn">
											<button className="btn btn-sm btn-light dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"></button>
											<ul className="dropdown-menu dropdown-menu-end">
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'thismonth')}>Este mes</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'lastmonth')}>Mes pasado</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'thisyear')}>Este año</a></li>
												<li><a className="dropdown-item" href="." onClick={(e) => setDatesRange(e, 'lastyear')}>Año pasado</a></li>
											</ul>
										</span>
									</div>
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
							<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<th>Proveedor</th>
											<th>Nº</th>
											<th>Fecha</th>
											<th>Base</th>
											<th>Impuestos</th>
											<th>Total</th>
											<th style={{width: '40px'}}></th>
										</tr>
									</thead>
									<tbody>
										{ invoices.data?.length > 0 &&
											invoices.data.map((el, idx) => {
												return ( 
													<React.Fragment key={'p'+idx}>
														<tr>
															<td>
																{el.provider?.business_name ?? '-'}
																<div><small>{el.provider?.vatnumber}</small></div>
															</td>
															<td>{ el.number }</td>
															<td>{ el.date ? moment(el.date).format('DD-MM-YYYY') : '-' }</td>
															<td>{ formatNumber(el.base) } €</td>
															<td>
																{el.taxes?.map((iEl, iIdx) => {
																	return <div key={iIdx}>{iEl.name}: {formatNumber(iEl.amount)} €</div>
																})}
															</td>
															<td>{ formatNumber(el.total) } €</td>
															<td>
																<button onClick={() => viewDocument(el.file)} className="btn btn-sm p-0 px-1 btn-primary"><i className="bi bi-save text-white"></i></button>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ invoices.data && !invoices.data.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ invoices.data === undefined && <TrSkeleton rows={skeletonRows} columns={10} /> }
									</tbody>
									<tfoot>
										<tr className="sbold">
											<td colSpan="3"></td>
											<td className="text-end">{ formatNumber(invoices.total_sum) } €</td>
											<td colSpan="4"></td>
										</tr>
									</tfoot>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={invoices?.current_page}
									max={invoices?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


