import axios from 'axios';

export default class Contracts {

	static axiosCancelToken = null;

	static list = (params) => {
		this.newAxiosCancelToken();

		return axios.get('api/app/contracts/list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
		})
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}