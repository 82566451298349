import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { NavLink } from "react-router-dom";
import TrSkeleton from 'components/TrSkeleton';
import { default as NoticesService } from 'services/Notices';
import moment from 'moment';

const Table = styled.table`
	thead {
		th {
			white-space: nowrap;
		}
	}

	thead, tbody {
		th, td {
			&:nth-child(1) {
				width: 100px;
			}

			&:nth-child(2) {
				width: 120px;
			}

			&:nth-child(3) {
				width: 100px;
			}
		}
	}
`;

let axiosCancelToken = null;

export default function Notices() {
	let [notices, setNotices] = useState(undefined);
	let [skeletonRows, setSkeletonRows] = useState(5);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getNotices = async () => {
			let notices = await NoticesService.list();
			setNotices({...notices});
			setSkeletonRows(notices.data.length);
		}

		getNotices();
	}, []);


	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Incidencias</h1>

					<div className="ms-auto">
						<NavLink to="/" className="btn btn-sm btn-light">Volver</NavLink>
					</div>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<th>Referencia</th>
											<th>Fecha</th>
											<th>Estado</th>
											<th>Detalle</th>
										</tr>
									</thead>
									<tbody>
										{ notices?.data?.length > 0 &&
											notices.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr>
															<td>{el.reference}</td>
															<td>{moment(el.opened_at).format('DD-MM-YYYY')}</td>
															<td>
																{el.status === 'pending' && <span className="badge bg-secondary">Pendiente</span>}
																{el.status === 'progress' && <span className="badge bg-primary">En curso</span>}
																{el.status === 'expired' && <span className="badge bg-danger">Vencido</span>}
																{el.status === 'closed' && <span className="badge bg-dark">Cerrado</span>}
															</td>
															<td>{el.description}</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ notices && !notices.data?.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ notices === undefined && <TrSkeleton rows={skeletonRows} columns={4} /> }
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


