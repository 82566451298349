import useStore from "store";

export const getCommunity = () => {
	let community = {...useStore.getState().community};

	// Overwrite if exists from query params
	const params = new URLSearchParams(window.location.search);
	const community_id = parseInt(params.get('community_id'));
	if ( community_id && community_id !== community?.id ) {
		community = {
			id: community_id,
			name: 'community ' + community_id,
			from_query_param: true
		};
	}

	return community;
}
 
export function setCommunity(data, reload = false, clearHistory = true, redirect = null) {
	useStore.getState().dispatch({
		type: 'setCommunity',
		data: data,
		forceReload: reload ? Math.random() : null
	});

	if ( clearHistory ) {
		window.location.href = redirect ?? process.env.PUBLIC_URL;
	}
} 