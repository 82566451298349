import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { NavLink } from "react-router-dom";
import TrSkeleton from 'components/TrSkeleton';
import { openPopupWindow } from 'helpers/generic';
import { default as ReceiptsService } from 'services/Receipts';
import { default as PropertiesService } from 'services/Properties';

const Table = styled.table`
	thead, tbody {
		th, td {
			&:nth-child(3),
			&:nth-child(4) { 
				white-space: nowrap;
				text-align: right;
				
				@media (min-width: 1100px) {
					width: 100px;
				}
			}

			&:nth-child(2), 
			&:nth-child(5) { 
				width: 100px;
			}
		}
	}
`;

let axiosCancelToken = null;

export default function Receipts() {
	let [properties, setProperties] = useState(undefined);
	let [propertyId, setPropertyId] = useState(null);
	let [years, setYears] = useState([]);
	let [year, setYear] = useState(moment().format('YYYY'));
	let [receipts, setReceipts] = useState(undefined);
	let [skeletonRows, setSkeletonRows] = useState(5);

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getReceipts = async () => {
			let receipts = await ReceiptsService.list(year, propertyId);
			setReceipts([...receipts]);
			setSkeletonRows(receipts.length);
		}
		getReceipts();
	}, [year, propertyId]);

	useEffect(() => {
		const getProperties = async () => {
			let properties = await PropertiesService.list();
			setProperties([...properties]);
		}
		getProperties();
	}, []);

	useEffect(() => {
		const getYears = async () => {
			let years = await ReceiptsService.yearsList();
			setYears([...years]);
		}
		getYears();
	}, []);


	const viewReceiptPdf = async (id) => {
		let receiptPdfString = await ReceiptsService.downloadPdf(id);
		
		let objectUrl = URL.createObjectURL(receiptPdfString);
		openPopupWindow(objectUrl);
	}

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Recibos</h1>

					<div className="ms-auto">
						<NavLink to="/" className="btn btn-sm btn-light">Volver</NavLink>
					</div>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
					<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-4 mb-2 mt-2 mb-md-0 mt-md-0">
									<select className="form-control form-control-sm" onChange={(e) => setPropertyId(e.target.value)} value={propertyId ?? ''}>
										<option value="">- Propiedad -</option>
										{ properties?.map((el, idx) => {
											return <option value={el.id} key={idx}>{el.name}</option>;
										})}
									</select>
								</div>
								<div className="col-md-2">
									<select className="form-control form-control-sm" onChange={(e) => setYear(e.target.value)} value={year ?? ''}>
										<option value="">- Año -</option>
										{ years?.map((el, idx) => {
											return <option value={el} key={idx}>{el}</option>;
										})}
									</select>
								</div>
								<div className="col-md-6"></div>
							</div>
						</div>

						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<th>Propiedad</th>
											<th>Nº</th>
											<th>Fecha</th>
											<th>Importe</th>
											<th>Estado</th>
										</tr>
									</thead>
									<tbody>
										{ receipts?.length > 0 &&
											receipts.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr>
															<td>{el.property}</td>
															<td>{el.number}</td>
															<td>{moment(el.date).format('DD-MM-YYYY')}</td>
															<td>{parseFloat(el.amount).toFixed(2)} €</td>
															<td>
																<div className="d-flex">
																	<div className="me-2">
																		{el.status === 'unpaid' && <div className="text-secondary">Pendiente</div>}
																		{el.status === 'paid' && <div className="text-success">Pagado</div>}
																		{el.status === 'partial' && <div className="text-indigo">Pago parcial</div>}
																		{el.status === 'refunded' && <div className="text-danger">Devuelto</div>}
																	</div>
																	<button onClick={() => viewReceiptPdf(el.id)} className="btn btn-primary btn-sm p-0 px-1 text-white ms-auto"><i className="bi bi-download"></i></button>
																</div>
															</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ receipts && !receipts.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ receipts === undefined && <TrSkeleton rows={skeletonRows} columns={5} /> }
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


