import axios from 'axios';

export default class Properties {

	static axiosCancelToken = null;

	static list = (params) => {
		this.newAxiosCancelToken();

		return axios.get('api/app/properties/list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data;
		})
	}

	static listAll = (params) => {
		this.newAxiosCancelToken();

		return axios.get('api/app/all-properties/list', {
			params: params,
			cancelToken: this.axiosCancelToken.token
		}).then((response) => {
			return response.data;
		}).catch((error) => {
			if ( axios.isCancel(error) ) return null;
			if ( error.response.data && error.response.data.errors ) return error.response.data;
		})
	}

	static cancel = () => {
		this.axiosCancelToken.cancel();
	}

	static newAxiosCancelToken = () => {
		this.axiosCancelToken = axios.CancelToken.source();
	}
	
}