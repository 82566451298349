import React, { useState, useEffect } from 'react';
import { useSearchParams } from "react-router-dom";
import styled from 'styled-components';
import LoggedLayout from 'layouts/LoggedLayout';
import { NavLink } from "react-router-dom";
import TrSkeleton from 'components/TrSkeleton';
import { default as TreasuryService } from 'services/Treasury';
import Paginator from 'components/Paginator';
import { formatNumber } from 'helpers/generic';

const Table = styled.table`
	thead {
		th {
			white-space: nowrap;
		}
	}

	thead, tbody {
		th, td {
			&:nth-child(1) {
				
			}

			&:nth-child(2) {
				width: 200px;
			}

			&:nth-child(3) {
				width: 80px;
				text-align: right;
			}
		}
	}
`;

export default function Treasury() {
	const [queryParams] = useSearchParams();

	let [treasuries, setTreasuries] = useState(undefined);
	let [skeletonRows, setSkeletonRows] = useState(5);
	let [page, _setPage] = useState(queryParams.get('page') ?? undefined);
	const setPage = (page) => {
		setTreasuries({...treasuries, data: undefined});
		_setPage(page);
	}

	useEffect(() => {
		const getTreasuries = async () => {
			let treasuries = await TreasuryService.list({
				page: page
			});
			setTreasuries({...treasuries});
			setSkeletonRows(treasuries?.data?.length);
		}

		getTreasuries();
	}, [page]);


	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Tesorería</h1>

					<div className="ms-auto">
						<NavLink to="/" className="btn btn-sm btn-light">Volver</NavLink>
					</div>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<th>Nombre</th>
											<th>Cuenta</th>
											<th>Saldo</th>
										</tr>
									</thead>
									<tbody>
										{ treasuries?.data?.length > 0 &&
											treasuries.data.map((el, idx) => {
												return ( 
													<React.Fragment key={idx}>
														<tr>
															<td>{el.name}</td>
															<td>{el.data?.iban}</td>
															<td>{formatNumber(el.balance ?? 0)}</td>
														</tr>
													</React.Fragment>
												);
											})
										}

										{ treasuries && !treasuries.data?.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ treasuries === undefined && <TrSkeleton rows={skeletonRows} columns={3} /> }
									</tbody>
								</Table>
							</div>
						</div>
						<div className="card-footer p-3 d-flex justify-content-end">
							<div className="d-inline-block">
								<Paginator
									min={1}
									current={treasuries?.current_page}
									max={treasuries?.last_page}
									changeCallback={(page) => setPage(page)}
								/>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


