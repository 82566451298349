import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Explorer from './Explorer';
import { getCommunity } from 'helpers/community';

const DocumentsContainer = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
`;

let axiosCancelToken = null;

export default function Documents(props) {
	const [folder, _setFolder] = useState([]);
	const folderRef = useRef(folder?.id);
	const setFolder = (folder) => {
		folderRef.current = {...folder};
		_setFolder(folder);
	}

	const [community, _setCommunity] = useState(props.community ?? getCommunity());
	const communityRef = useRef(community?.id);
	const setCommunity = (community) => {
		communityRef.current = {...community};
		_setCommunity(community);
	}

	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	const openFolder = useCallback((el) => {
		axios.get('/api/app/documents/get-folder/' + (el?.id ?? -1), {
			params: {
				community_id: community?.id
			},
			cancelToken: axiosCancelToken.token
		}).then((response) => {
			setFolder({...response.data});
	  	}).catch((error) => {
			if ( axios.isCancel(error) ) return;
		});	
	}, [community?.id, props.reloadFolder]);

	useEffect(() => {
		setCommunity(props.community ?? getCommunity());
	}, [props.community]);

	// When community changes, it fires useCallback of openFolder and openFolder fires this useEffect
	useEffect(() => {
		let folderToOpenId = null;
		if ( folderRef.current?.id ) {
			folderToOpenId = folderRef.current?.id;

			// If trying to load folder from other community, load root foolder
			if ( folderRef.current.community_id && communityRef.current.id && 
					folderRef.current.community_id !== communityRef.current ) {
				folderToOpenId = null;
			}
		}
		if ( props.rootFolderId ) folderToOpenId = props.rootFolderId;			

		openFolder({id: folderToOpenId});
	}, [openFolder, props.rootFolderId]);

	return (
		<DocumentsContainer>
			<Explorer
				folder={folder}
				rootFolderId={props.rootFolderId ?? -1}
				openFolder={openFolder}
				community={community}
				recipientsType={props.recipientsType}
				recipientsIds={props.recipientsIds}
			/>
		</DocumentsContainer>
	);
}


