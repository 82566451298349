import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import moment from 'moment';
import 'moment/locale/es';
import axios from 'axios';
import styled from 'styled-components';
import LoggedLayout from "layouts/LoggedLayout";
import ThSortable from "components/ThSortable";
import TrSkeleton from "components/TrSkeleton";
import { formatNumber } from 'helpers/generic';
import EmpoweredSelector from 'components/EmpoweredSelector';
import { default as PrevisionsService } from 'services/Previsions';
import { default as EstimatesService } from 'services/Estimates';
import { default as PropertiesService } from 'services/Properties';

const Table = styled.table`
	& > thead > tr > th, 
	& > tbody > tr > td {
		&:nth-child(2) {
			width: 50px;
			text-align: right;
		}

		&:nth-child(3) {
			width: 140px;
		}

		&:nth-child(5) {
			white-space: nowrap;
			width: 100px;
			text-align: right;
		}
	}

	tr {
		&.passed {
			opacity: 0.45;
		}
	}
`;

let axiosCancelToken = null;

export default function Receipts() {
	let [previsions, setPrevisions] = useState(undefined);
	let [estimate, setEstimate] = useState({});
	let [property, setProperty] = useState({});
	let [skeletonRows, setSkeletonRows] = useState(5);

	const loadEstimates = async (input, callback, args) => {
		let estimates = await EstimatesService.list({property_id: args.property_id, no_paginate: true});
		
		let formatted = estimates.map((el) => {
			el.name = 'Presupuesto ' + (el.type === 'ordinary' ? 'Ordinario' : 'Extraordinario') + ' ' + el.number_full;
			return {
				value: el, 
				label: el.name
			};
		});
		console.log(formatted)
		callback(formatted);
	}

	const loadProperties = async (input, callback) => {
		let properties = await PropertiesService.list({search: input, no_paginate: true});
		
		let formatted = properties.map((el) => {
			return {
				value: el, 
				label: el.name + ' (' + el.number + ')'
			};
		});
		callback(formatted);
	}


	useEffect(() => {
		axiosCancelToken = axios.CancelToken.source();
	
		return function cleanup() {
           	axiosCancelToken.cancel();
        }
	}, []);

	useEffect(() => {
		const getPrevisions = async () => {
			let previsions = await PrevisionsService.list(property?.id, estimate?.id);
			setPrevisions([...previsions]);
			setSkeletonRows(previsions.length);
		}

		getPrevisions();
	}, [property?.id, estimate?.id]);

	return (
		<LoggedLayout>
			<section>
				<div className="page-title">
					<h1>Previsiones de pago</h1>
				</div>

				<div className="page-content">
					<div className="card border-0 shadow-sm">
						<div className="card-header bg-white p-3">
							<div className="row">
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<EmpoweredSelector
										load={loadEstimates}
										args={{property_id: property?.id}}
										onChange={(value) => setEstimate(value)}
										timeout={250}
										label={estimate?.name}
										placeholder="- Presupuesto -"
										value={estimate?.id}
										noSearcheable={true}
									/>
								</div>
								<div className="col-md-2 mb-2 mt-2 mb-md-0 mt-md-0">
									<EmpoweredSelector
										load={loadProperties}
										onChange={(value) => setProperty(value)}
										timeout={250}
										label={property?.name}
										placeholder="- Propiedad -"
										value={property?.id}
									/>
								</div>
								<div className="col-md-7 mb-2 mt-2 mb-md-0 mt-md-0">
									
									
								</div>
							</div>
						</div>
						<div className="card-body p-0">
							<div className="table-responsive table-responsive-carded">
								<Table className="table table-hover table-sortable table-carded">
									<thead>
										<tr>
											<th>Presupuesto</th>
											<th>Emisión</th>
											<th>Fecha</th>
											<th>Propiedad</th>
											<th>Importe</th>
										</tr>
									</thead>
									<tbody>
										{ previsions?.length > 0 &&
											previsions.map((el, idx) => {
												return ( 
													<tr key={idx} className={el.passed ? 'passed' : ''}>
														<td>{el.estimate.name}</td>
														<td>{el.number}</td>
														<td>
															{ el.date && 
																<React.Fragment>
																	{moment(el.date).locale('es').format('MMMM')} / {moment(el.date).format('YYYY')}
																</React.Fragment> 
															}
															{ !el.date && '-' }	
														</td>
														<td>{el.property.name}&nbsp;</td>
														<td>{formatNumber(el.amount)} €</td>
													</tr>
												);
											})
										}

										{ previsions && !previsions.length && <tr><td colSpan="100%">No hay datos disponibles</td></tr> }

										{ previsions === undefined && <TrSkeleton rows={skeletonRows} columns={5} /> }
									</tbody>
								</Table>
							</div>
						</div>
					</div>
				</div>
			</section>
		</LoggedLayout>
	);
}


